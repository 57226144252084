import React, {createContext, ReactElement, useContext, useMemo} from "react";

import { useNavigate } from "react-router-dom";
import axios, {AxiosError} from "axios";
import AuthResponse from "../models/AuthResponse";
import {Page, PathMap} from "../PathProvider";
import {GlobalState, useStateMachine} from "little-state-machine";
import {FullUser} from "../models/User";

const AuthContext = createContext<AuthContextTypes>({
    user: null,
    login : () => null,
    loginAfterRegister : () => null,
    logout : () => null,
    setNewAccessToken: () => null,
    updateUserAfterEdit: () => null
});

interface AuthProviderProps {
    children : ReactElement
}

export type AuthContextTypes = {
    user: AuthResponse | null;
    login : (data: AuthResponse, isRegister ?: boolean) => void;
    loginAfterRegister : (data : AuthResponse) => void;
    logout : (data?: string) => void;
    setNewAccessToken : (data : string) => void;
    updateUserAfterEdit : (data : FullUser) => void;
};

function updateUser(state : GlobalState, user : AuthResponse) {
    return {
        ...state,
        user : user
    };
}

function clearUser(state : GlobalState) {
    return {
        ...state,
        user : null
    };
}

export function AuthProvider(props : AuthProviderProps) {
    const {actions, state : {user}} = useStateMachine({clearUser, updateUser});
    const navigate = useNavigate();

    const loginAfterRegister = (data : AuthResponse) => {
        login(data, true);
    }

    // call this function when you want to authenticate the user
    const login = (data : AuthResponse, isRegister ?: boolean) => {
        actions.updateUser(data);

        // we use PathMap here instead of usePath because the PathProvider is inside the AuthProvider, thus we don't
        // have access to the usePath hook in here
        if (data.admin) {
            navigate(PathMap.get(Page.AdminViewBuildings)?.path!);
        } else if (isRegister) {
            // if this the first login after registration then we want to go straight to the add tenant page
            navigate(
                data.admin? PathMap.get(Page.SearchTenant)?.adminPath! : PathMap.get(Page.SearchTenant)?.path!,
                { state: { successMessage : 'Account successfully created. Please add tenants.'} }
            );
        } else {
            navigate(PathMap.get(Page.NonAdminViewBuildings)?.path!);
        }
    };

    // call this function to sign out logged in user
    const logout = (message? : string) => {
        let loginPage:string = user?.admin ? PathMap.get(Page.AdminLogin)?.path! : PathMap.get(Page.Login)?.path!
        actions.clearUser();
        // we use PathMap here instead of usePath because the PathProvider is inside the AuthProvider, thus we don't
        // have access to the usePath hook in here
        navigate(loginPage, { replace: true, state : {warningMessage : message ?? 'You have been successfully logged out'} });
    };

    const setNewAccessToken = (accessToken : string) => {
        if (user) {
            actions.updateUser({...user, accessToken : accessToken});
        } else {
            logout();
        }
    };

    const updateUserAfterEdit = (fullUser : FullUser) => {
        if (user) {
            actions.updateUser({
                ...user,
                fullName : `${fullUser.lastName} ${fullUser.firstName}`,
                email : fullUser.email,
                username : fullUser.login
            })
        } else {
            logout();
        }

    };

    const value = useMemo<AuthContextTypes>(
        () => ({
            user,
            login,
            loginAfterRegister,
            logout,
            setNewAccessToken,
            updateUserAfterEdit
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};