import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./auth/AuthProvider";
import {AxiosProvider} from "./AxiosProvider";
import {MessageProvider} from "./hooks/useMessageService";
import {PathProvider} from "./PathProvider";
import {createStore, StateMachineProvider} from "little-state-machine";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

createStore({ user : null});

root.render(
    <BrowserRouter>
        <MessageProvider>
            <StateMachineProvider>
                <AuthProvider>
                    <AxiosProvider>
                        <PathProvider>
                            <App />
                        </PathProvider>
                    </AxiosProvider>
                </AuthProvider>
            </StateMachineProvider>
        </MessageProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
