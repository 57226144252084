import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";

const AcknowledgementText = styled.div`
    background-color: #d7ecfa;
    color: #0a0a0a;
    margin: 0 0 1rem 0;
    padding: 1rem;
    border: 1px solid rgba(10,10,10,.25);
    border-radius: 0;
`

const FlexRowContainer = styled.div<{gap ?: number}>`
    display: flex;
    flex-direction: row;
    gap : ${props => props.gap ?? '1rem'}
`

const FlexColumnContainer = styled.div<{gap ?: number}>`
    display: flex;
    flex-direction: column;
    gap : ${props => props.gap ?? '1rem'}
`

const GridColumnContainer = styled.div<{columns : number}>`
    display: grid;
    grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
    gap: 1rem
`

const PageTitle = styled.div` 
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 2rem;
`

const Header = styled.div` 
  display: block;
  margin: 0;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.8;
  color: #0a0a0a;
`

const SmallHeader = styled.div` 
  display: block;
  margin: 0;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.8;
  color: #0a0a0a;
`

const Divider = styled.div`
  border-bottom: 1px solid #D2D2D2;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const NoWrap = styled.div`
  white-space: nowrap;
`

const WrapDate = styled.div`
  word-break: initial;
`

const StyledLink = styled.a`
  text-decoration: none;
  color: #0060A9;
`

const Underline = styled.span`
  text-decoration: underline;
`

const BoldUnderline = styled.span`
  font-weight: bold;
  text-decoration: underline;
`

const inlineActionItemStyles = css`
  text-decoration: none;
  color: #0060A9;
  &:visited {
    color: #0060A9;
  }
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`

const InlineActionItem = styled.span`
  ${inlineActionItemStyles}
`

const InlineLink = styled(Link)`
  ${inlineActionItemStyles}
  display: contents !important;
`

const WarningBox = styled.div`
  background: #faf7e5;
  border-left: 5px solid #877101;
  display: flex;
  padding: .75rem;
  border-radius: 6px;
  margin: 1rem 0;
  align-items: flex-start;
`

const ErrorBox = styled.div`
  background: #fae5e5;
  border-left: 5px solid #c00;
  display: flex;
  padding: .75rem;
  border-radius: 6px;
  margin: 1rem 0;
  align-items: flex-start;
`

const InfoBox = styled.div`
  background: #E6F2FA;
  display: flex;
  padding: .75rem;
  border-left: 5px solid #0060A9;
  border-radius: 6px;
  margin: 1rem 0;
  align-items: flex-start;
`

export {
    AcknowledgementText,
    FlexRowContainer,
    FlexColumnContainer,
    PageTitle,
    Header,
    Divider,
    GridColumnContainer,
    NoWrap,
    WrapDate,
    Underline,
    BoldUnderline,
    StyledLink,
    InlineLink,
    inlineActionItemStyles,
    InlineActionItem,
    WarningBox,
    ErrorBox,
    InfoBox
}