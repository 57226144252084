import styled from "styled-components";

const MessageContainer = styled.div`
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: 2rem;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
`

const MessageList = styled.ul`
  padding: 0;
  margin : 0
`

const ErrorContainer = styled(MessageContainer)`
  background: #fae5e5;
  color: #c00;
  border: 1px solid #c00;
`

const WarningContainer = styled(MessageContainer)`
  background: #faf7e5;
  color: #877101;
  border: 1px solid #877101;
`

const SuccessContainer = styled(MessageContainer)`
  background: #d9f5d5;
  color: #3d8701;
  border: 1px solid #3d8701;
`
const InfoContainer = styled(MessageContainer)`
  background: #e6f2fa;
  color: #0060a9;
  border: 1px solid #0060a9;
`

const MessageListItem = styled.li`
  margin-left: 1rem;
  &:only-child {
    list-style-type: none;
    margin-left: 0;
  }
`

export {MessageList, ErrorContainer, WarningContainer, SuccessContainer, InfoContainer, MessageListItem}