import React, {createContext, ReactElement, useContext, useMemo} from "react";

import {useAuth} from "./auth/AuthProvider";

export const enum Page {
    AdminLogin,
    Login,
    ForgotCredentials,
    ResetForgotPassword,
    ResetPassword,
    AcknowledgementPage,
    AuditLog,
    ErrorFallback,
    AddBuildingAssoc,
    ReviewAddBuildingAssoc,
    DeleteBuilding,
    Register,
    ReviewRegister,
    AdminViewUsers,
    AdminViewUserBuildings,
    NonAdminViewBuildings,
    AdminViewBuildings,
    AdminViewBuildingUsers,
    AdminAddBuilding,
    SearchTenant,
    SearchTenantResults,
    SearchTenantsResultsReview,
    ManageTenants,
    EditBuilding,
    ReviewEditBuilding,
    EditUser,
    ReviewEditUser,
    ManageAccount,
    UsageRequestPage,
    DeleteTenant,
    ForcedResetPassword
}


type PathValue = {
    title : string
} & ({
    path ?: string
    adminPath : string
} | {
    path: string,
    adminPath ?: never
} | {
    path: string,
    adminPath : string
})

export const PathMap : Map<Page, PathValue> = new Map();

function loadPathMap() {
    PathMap.set(Page.Login,               {path : '/login',                 title : 'Login'});
    PathMap.set(Page.ForgotCredentials,   {path : '/forgotCredentials',     title : 'Forgot Credentials'});
    PathMap.set(Page.ResetForgotPassword, {path : '/resetForgotPassword', title : 'Forgot Password Reset'});
    PathMap.set(Page.AdminLogin,          {path : '/adminLogin',          title : 'Admin Login'});
    PathMap.set(Page.ForcedResetPassword,      {path : '/forcedResetPassword', title : 'Reset Password'});


    PathMap.set(Page.Register,           {path : '/register',      title : 'Register'});
    PathMap.set(Page.ReviewRegister,     {path : '/reviewRegister',title : 'Review Registration'});
    PathMap.set(Page.ResetPassword,      {path : '/resetPassword', title : 'Reset Password'});
    PathMap.set(Page.AcknowledgementPage,{path : '/acknowledge',   title : 'Acknowledgement'});

    // There is an admin version of a page instead of an adminPath because the Admins are served a different react component
    // view buildings
    PathMap.set(Page.NonAdminViewBuildings, {path : '/buildings',        title : 'All Buildings'});
    PathMap.set(Page.AdminViewBuildings,    {path : '/admin/buildings',  title : 'All Buildings'});

    PathMap.set(Page.AdminViewUsers,         {path : '/admin/users',                                         title : 'All Users'});
    PathMap.set(Page.AdminViewUserBuildings, {path : `${PathMap.get(Page.AdminViewUsers)?.path}/buildings`,  title : 'User\'s Buildings'});
    PathMap.set(Page.AdminViewBuildingUsers, {path : `${PathMap.get(Page.AdminViewBuildings)?.path}/users`,  title : 'Building\'s Users'});

    // There is an admin version of a page instead of an adminPath because the Admins are served a different react component
    // add building/association
    PathMap.set(Page.AddBuildingAssoc,          {adminPath : `${PathMap.get(Page.AdminViewUserBuildings)?.path}/add`, path : `${PathMap.get(Page.NonAdminViewBuildings)?.path}/add`,  title : 'Add Building'});
    PathMap.set(Page.ReviewAddBuildingAssoc,    {adminPath : `${PathMap.get(Page.AddBuildingAssoc)?.path}/review`, path : `${PathMap.get(Page.AddBuildingAssoc)?.path}/review`,    title : 'Review - Add Building'});
    PathMap.set(Page.AdminAddBuilding,          {path : `${PathMap.get(Page.AdminViewBuildings)?.path}/add`,     title : 'Add Buildings'})

    // edit building
    PathMap.set(Page.EditBuilding,       {adminPath : `${PathMap.get(Page.AdminViewUserBuildings)?.path}/edit`,  path : `${PathMap.get(Page.NonAdminViewBuildings)?.path}/edit`,   title : 'Edit Building'});
    PathMap.set(Page.ReviewEditBuilding, {adminPath : `${PathMap.get(Page.EditBuilding)?.adminPath}/review`, path : `${PathMap.get(Page.EditBuilding)?.path}/review`, title : 'Review Edit Building'});

    PathMap.set(Page.AuditLog, {path : '/activityLog', title : 'Activity Log'});

    // delete building association
    PathMap.set(Page.DeleteBuilding, {adminPath : `${PathMap.get(Page.AdminViewUserBuildings)?.path}/delete`, path : `${PathMap.get(Page.NonAdminViewBuildings)?.path}/delete`, title : 'Delete Building'});

    // edit user
    PathMap.set(Page.EditUser,       {path : `${PathMap.get(Page.AdminViewUsers)?.path}/edit`, title : 'Edit Building'});
    PathMap.set(Page.ReviewEditUser, {path : `${PathMap.get(Page.EditUser)?.path}/review`, title : 'Review Edit Building'});

    PathMap.set(
        Page.ManageTenants,
        {adminPath: `${PathMap.get(Page.AdminViewUserBuildings)?.path}/manageTenants`, path : `${PathMap.get(Page.NonAdminViewBuildings)?.path}/manageTenants`, title : 'Manage Tenants'}
    );

    PathMap.set(
        Page.SearchTenant,
        {adminPath : `${PathMap.get(Page.ManageTenants)?.adminPath}/searchTenant`, path : `${PathMap.get(Page.ManageTenants)?.path}/searchTenant`, title : 'Search Tenant'}
    );

    PathMap.set(
        Page.SearchTenantResults,
        {adminPath : `${PathMap.get(Page.SearchTenant)?.adminPath}/results`, path : `${PathMap.get(Page.SearchTenant)?.path}/results`, title : 'Results'}
    );
    PathMap.set(
        Page.SearchTenantsResultsReview,
        {adminPath : `${PathMap.get(Page.SearchTenantResults)?.adminPath}/review`, path : `${PathMap.get(Page.SearchTenantResults)?.path}/review`, title : 'Review Results'}
    );

    // delete tenant
    PathMap.set(Page.DeleteTenant, {adminPath : `${PathMap.get(Page.ManageTenants)?.adminPath}/delete`, path : `${PathMap.get(Page.ManageTenants)?.path}/delete`, title : 'Delete Tenant'});

    // non-admin user only paths
    PathMap.set(Page.ManageAccount, {path : '/manageAccount', title : 'Manage My Account'});
    PathMap.set(Page.UsageRequestPage,  {path : `${PathMap.get(Page.NonAdminViewBuildings)?.path}/usageRequest`, title : 'Usage Request'});
}

const PathContext = createContext<PathContextTypes>({ get : () => '', getTitleFromPathName : () => ''});

interface PathProviderProps {
    children : ReactElement
}

export type PathContextTypes = {
    get : (data : Page) => string;
    getTitleFromPathName : (data : string) => string;
};

export function PathProvider(props : PathProviderProps) {
    const auth = useAuth();

    const get = (path : Page) : string => {
        if (PathMap.size === 0) { loadPathMap(); }

        let pathValue : PathValue | undefined = PathMap.get(path);

        if (!pathValue) { throw new Error(`No paths defined for ${path}`); }

        if (auth.user?.admin && pathValue.adminPath) { return pathValue.adminPath; }

        return pathValue?.path ?? '';
    };

    const getTitleFromPathName = (pathString : string) : string => {
        let it = PathMap.values();
        let nextValue = it.next();

        while (nextValue) {
            let pathValue = nextValue.value;

            if (!pathValue) { return ''; }

            if (pathValue.path === pathString || pathValue.adminPath === pathString) {
                return pathValue.title;
            }

            nextValue = it.next();
        }

        return ''
    }

    const value = useMemo<PathContextTypes>(
        () => ({
            get,
            getTitleFromPathName
        }),
        [auth.user]
    );

    return <PathContext.Provider value={value}>{props.children}</PathContext.Provider>;
}

export const usePath = () => {
    return useContext(PathContext);
};